import * as React from "react";
import Layout from "@components/layout";
import SEO from "@components/seo";
import PortfolioComponent from "@components/Projects"
import { withPrefix } from "gatsby"
import Helmet from "react-helmet"

const PortfolioPage = ({ uri }) => (
  <Layout path={uri}>
    <SEO title="Portfolio" />
    <h1 style={{ display: "none" }}>Portfolio</h1>
    <PortfolioComponent />
    <p>
    </p>
    <Helmet>
      <script src={withPrefix('chatbot.js')} type="text/javascript" />
    </Helmet>
  </Layout>
);

export default PortfolioPage;
