// extracted by mini-css-extract-plugin
export const project = "Portfolio-module--project--3nJJ-";
export const title = "Portfolio-module--title--341xS";
export const top_image = "Portfolio-module--top_image--eFgE4";
export const projects_carousel = "Portfolio-module--projects_carousel--2kT9c";
export const more = "Portfolio-module--more--1gpQN";
export const more_cards = "Portfolio-module--more_cards--1FqV-";
export const card_title_container = "Portfolio-module--card_title_container--XUbGv";
export const card_image = "Portfolio-module--card_image--138Mb";
export const card_icon = "Portfolio-module--card_icon--3yHps";
export const bouncy = "Portfolio-module--bouncy--24A1X";
export const title_container = "Portfolio-module--title_container--3zd0e";
export const pop = "Portfolio-module--pop--1--zb";