import * as React from "react";
import { useState, useEffect } from "react";
import { Link } from "gatsby";
import * as styles from "./Portfolio.module.scss";
import ContactForm from "@components/ContactForm";
import ProjectsCarousel from "./ProjectsCarousel";
import DataService from "@utils/DataService";

import servicesImg from "@images/portfolio/icon_services.svg";
import aboutImg from "@images/portfolio/icon_about.svg";

const cardsData = {
  services: {
    title: "Services From Magyk Software",
    icon: servicesImg,
    image: ""
  },
  about: {
    title: "About Our Company",
    icon: aboutImg,
    image: ""
  }
};

const MoreCard = ({ title, icon, image }) => {
  return (
    <div>
      <Link to="/services">
        <div className={styles.card_title_container}>
          <h2>{title}</h2>
          <img src={icon} className={styles.card_icon} alt="Card icon" />
        </div>
        <img src={image} className={styles.card_image} alt="Card" />
      </Link>
    </div>
  );
};

const PortfolioComponent = () => {
  const [fetchedProjects, setFetchedProjects] = useState([]);

  useEffect(() => {
    DataService.getCMSData("project").then(data => {
      setFetchedProjects(data);
    });
  }, []);

  return (
    <div
      className={`${styles.project} container`}
    >
      <div className={styles.title_container}>
        <h1 className={styles.title}></h1>
        <img src="" className={styles.top_image} alt="Carousel top" />
      </div>
      <div className={styles.projects_carousel}>
        {fetchedProjects?.length > 0 && (
          <ProjectsCarousel carouselData={fetchedProjects} />
        )}
      </div>
      <div>
        <h2 className={styles.more}>Find Out More</h2>
        <div className={styles.more_cards}>
          {Object.keys(cardsData).map((cardData, i) => (
            <MoreCard key={i} {...cardsData[cardData]} />
          ))}
        </div>
      </div>
      <ContactForm />
    </div>
  );
};

export default PortfolioComponent;
