import * as React from "react";
import { useState } from "react";
import { Link } from "gatsby";
import * as styles from "./ProjectsCarousel.module.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import cardBullish from "@images/portfolio/card_bullish_studio.png";

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className={`${styles.arrow} ${styles.prev_arrow}`} onClick={onClick} />
  );
}

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className={`${styles.arrow} ${styles.next_arrow}`} onClick={onClick} />
  );
}

const ProjectsSlider = ({ carouselData }) => {

  const [currentSlide, setCurrentSlide] = useState(0)

  const settings = {
    infinite: true,
    dots: true,
    customPaging: i => <div className={styles.custom_paging}></div>,
    beforeChange: (old, newSlide) => {setCurrentSlide(newSlide)},
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  // function createMarkup(content) {
  //   return {__html: content};
  // }
  

  return (
    <div className={styles.slider_container}>
      <Slider {...settings}>
        {carouselData && carouselData.map(singleProject => (
          <div> 
            <div
              className={styles.slide}
              style={{
                backgroundImage: `url(${carouselData[0].image})`,
                backgroundSize: "cover"
              }}
            >
              <Link to={`/app/projects/${currentSlide+1}`} className={styles.carousel_card_text}>
                <div>
                  <h2>
                    {singleProject.title}
                  </h2>
                  <p dangerouslySetInnerHTML={{__html: singleProject.content}} ></p>
                </div>
                <img src="" className={styles.project_inner_image} alt="project" ></img>
              </Link>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ProjectsSlider;
